var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.handleSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Gateadresse",
      "rules": [function (v) {
        return !!v || 'Fyll inn gateadresse';
      }],
      "required": ""
    },
    model: {
      value: _vm.locationValues.street,
      callback: function callback($$v) {
        _vm.$set(_vm.locationValues, "street", $$v);
      },
      expression: "locationValues.street"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Postnummer",
      "rules": [function (v) {
        return !!v || 'Fyll inn postnummer';
      }],
      "required": ""
    },
    model: {
      value: _vm.locationValues.postalCode,
      callback: function callback($$v) {
        _vm.$set(_vm.locationValues, "postalCode", $$v);
      },
      expression: "locationValues.postalCode"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Poststed",
      "rules": [function (v) {
        return !!v || 'Fyll inn poststed';
      }],
      "required": ""
    },
    model: {
      value: _vm.locationValues.postalArea,
      callback: function callback($$v) {
        _vm.$set(_vm.locationValues, "postalArea", $$v);
      },
      expression: "locationValues.postalArea"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "Aktiv"
    },
    model: {
      value: _vm.locationValues.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.locationValues, "isActive", $$v);
      },
      expression: "locationValues.isActive"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Detaljer"
    },
    model: {
      value: _vm.locationValues.details,
      callback: function callback($$v) {
        _vm.$set(_vm.locationValues, "details", $$v);
      },
      expression: "locationValues.details"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }