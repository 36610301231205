
import { api } from "@/api/api";
import { ApiGetAreaDto, ApiGetLocationDto } from "@/api/generated/Api";
import LocationModal from "@/components/administration/location/LocationModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTable from "@/components/shared/table/BaseTable.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "LocationPage",
  components: { LocationModal, BaseModal, BaseTable, BaseLayout },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const search = ref("");
    const area = ref<ApiGetAreaDto>();
    const locations = ref<ApiGetLocationDto[]>();
    const singleLocation = ref<ApiGetLocationDto>();
    const modalData = ref(getInitialModalData());
    const expandedItems = ref<ApiGetAreaDto[]>([]);

    const subheaders = [
      { text: "Gateadresse", value: "street" },
      { text: "Handlinger", value: "actions" },
      { text: "Detaljer", value: "details" },
      { text: "Postnummer", value: "postalCode" },
      {
        text: "Poststed",
        value: "postalArea",
      },
      { text: "Status", value: "isActive" },
    ];
    const mainheaders = [
      { text: "Lokasjon", value: "place" },
      { text: "Kommune", value: "municipality" },
      {
        text: "Fylke",
        value: "county",
      },
      { text: "Land", value: "country" },
    ];

    const getArea = async () => {
      area.value = (await api.area.getAreaByIdAsync(+route.params.areaId)).data;
    };
    const getLocations = async () => {
      locations.value = (await api.area.getLocationsAsync(+route.params.areaId)).data;
    };

    const loadData = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await Promise.all([getArea(), getLocations()]);
        if (area.value) {
          expandedItems.value = [area.value];
        }
      });
    };

    onMounted(() => {
      loadData();
    });

    const openNewLocation = useOpenModal(ModalType.Add, "adresse", modalData);
    const openEditLocation = useOpenModal(ModalType.Edit, "adresse", modalData);

    const displayLocation = async ({ areaId, id }: ApiGetLocationDto) => {
      singleLocation.value = (await api.area.getLocationAsync(areaId, id)).data;
      router.push({
        name: AdminRouteNames.Rooms,
        params: { areaId: areaId.toString(), locationId: id.toString() },
      });
    };

    return {
      openNewLocation,
      openEditLocation,
      modalData,
      displayLocation,
      singleLocation,
      area,
      locations,
      search,
      subheaders,
      mainheaders,
      expandedItems,
      loadData,
      areaId: computed(() => route.params.areaId),
      areasLink: () => useNavigateBack(AdminRouteNames.Areas),
    };
  },
});
