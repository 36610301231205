
import Vue from "vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import { useRoute, useStore } from "@/shared/useHelpers";
import { api } from "@/api/api";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { StoreState } from "@/store/store.state.interface";
import { ApiUpsertLocationDto } from "@/api/generated/Api";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";

const getLocationInitialData = (): ApiUpsertLocationDto => ({
  isActive: true,
});

export default defineComponent({
  name: "LocationModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    location: {
      type: Object as PropType<ApiUpsertLocationDto>,
      default: getLocationInitialData,
    },
    locationId: {
      type: Number,
    },
  },

  setup({ modalType, location, locationId }, context) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const locationValues = ref(deepCloneObject(location));

    const handleSubmit = async () =>
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();

        if (!isValid || !locationValues.value) {
          return;
        }

        if (modalType === ModalType.Add) {
          await api.area.createLocationAsync(+route.params.areaId, locationValues.value);
          openNotification(store, NotificationItemType.Success, "Stedet er lagt til");
        } else if (modalType === ModalType.Edit) {
          if (!locationId) {
            return;
          }
          await api.area.updateLocationAsync(+route.params.areaId, +locationId, locationValues.value);
          openNotification(store, NotificationItemType.Success, "Endringene er lagret");
        }
        context.emit("close");
        context.emit("update");
      });

    return {
      locationValues,
      handleSubmit,
    };
  },
});
