var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('LocationModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "location": _vm.modalData.existingItem,
      "locationId": _vm.modalData.existingItemId
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "update": _vm.loadData
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "pr-0"
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.areasLink
          }
        }, [_vm._v("mdi-arrow-left")])], 1), _c('v-col', {
          staticClass: "px-0",
          attrs: {
            "data-cy": "contract-lines-header"
          }
        }, [_vm._v(" Adresse for " + _vm._s(_vm.area.place) + " ")]), _c('v-col', {
          staticClass: "pl-0"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "max-width": "30%"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "right": "",
                  "color": "white"
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-message-question-outline ")])];
            }
          }])
        }, [_c('span', [_vm._v("Her finner du og kan legge til/redigere adresseinformasjon på lokasjonen. Før du oppretter nye, sjekk om adresse er lagt inn fra før. I feltet \"Detaljer\" kan du gi ekstra informasjon som er relevant for lokasjonen")])])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "openNewLocation"
          },
          on: {
            "click": _vm.openNewLocation
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til adresse på lokasjon ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('BaseTable', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.mainheaders,
      "items": [_vm.area],
      "expanded": _vm.expandedItems,
      "hidePaginator": true
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expandedItems = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "expanded-item",
      fn: function fn(_ref2) {
        var headers = _ref2.headers;
        return [_c('td', {
          staticClass: "pa-0",
          attrs: {
            "colspan": headers.length
          }
        }, [_c('BaseTable', {
          attrs: {
            "headers": _vm.subheaders,
            "items": _vm.locations,
            "search": _vm.search
          },
          scopedSlots: _vm._u([{
            key: "item.isActive",
            fn: function fn(_ref3) {
              var value = _ref3.value;
              return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
            }
          }, {
            key: "actions",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref5) {
                    var on = _ref5.on,
                        attrs = _ref5.attrs;
                    return [_c('v-icon', _vm._g(_vm._b({
                      staticClass: "mr-2",
                      on: {
                        "click": function click($event) {
                          return _vm.displayLocation(item);
                        }
                      }
                    }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
                  }
                }], null, true)
              }, [_c('span', [_vm._v("Vis rom ")])]), _c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref6) {
                    var on = _ref6.on,
                        attrs = _ref6.attrs;
                    return [_c('v-icon', _vm._g(_vm._b({
                      staticClass: "mr-2",
                      on: {
                        "click": function click($event) {
                          return _vm.openEditLocation(item, item.street);
                        }
                      }
                    }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
                  }
                }], null, true)
              }, [_c('span', [_vm._v("Rediger rom ")])])];
            }
          }], null, true)
        })], 1)];
      }
    }])
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }